import { Link } from "gatsby";
import React from "react";

interface Props {
  to: string;
}

export const LinkContainer: React.FC<Props> = ({ to, children }) => {
  return (
    <Link className="group relative inline-block" to={to}>
      <div
        className="absolute -inset-x-[6px] -inset-y-[2px] scale-90 rounded-lg transition-all duration-300 group-hover:scale-100 group-hover:bg-slate-200"
        aria-hidden
      />
      <div className="relative transition-all duration-100 group-active:scale-[0.98]">
        {children}
      </div>
    </Link>
  );
};
